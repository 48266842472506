.search-label-light {
    color: #303030;
}

.search-label-dark {
    color: #d1d1d1;
}

.bag-header-search-box {
    border-radius: 3px;
}

.bag-header-search-box-light {
    background-color: #fdfdfd;
    border: 1px solid #707070;
    color: #303030;
}

.bag-header-search-box-light input {
    background-color: #fdfdfd;
}

.bag-header-search-box-dark {
    background-color: #181818;
    border: 1px solid #505050;
    color: #d1d1d1;
}

.bag-header-search-box-dark input {
    background-color: #181818;
}

.bag-header-search-btns-box-light {
    color: #303030;
}

.bag-header-search-btns-box-dark {
    color: #e8e8e3;
}

.bag-header-search-btns-box button {
    transition: 0.2s;
    border-radius: 3px;
}

.filter-and-sort-btn-light {
    background-color: #79E2BC;
}

.filter-and-sort-btn-light:hover {
    background-color: #67BB9D;
}

.filter-and-sort-btn-dark {
    background-color: #217A61;
}

.filter-and-sort-btn-dark:hover {
    background-color: #1A5948;
}

.filter-and-sort-reset-btn-light {
    background-color: #2A8DE8;
}

.filter-and-sort-reset-btn-light:hover {
    background-color: #2370B8;
}

.filter-and-sort-reset-btn-dark {
    background-color: #1A66AC;
}

.filter-and-sort-reset-btn-dark:hover {
    background-color: #16446F;
}