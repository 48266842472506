.bag-content-light {
    color: #303030;
    background-color: #fafafa;
}

.bag-content-dark {
    color: #e8e8e3;
    background-color: #101010;
}

.bag-list-col-select-base {
    min-width: 1rem;
    max-width: 1rem;
}

.bag-list-col-slno-base {
    min-width: 3rem;
    max-width: 3rem;
}

.bag-list-col-id-base {
    min-width: 6rem;
    max-width: 6rem;
}

.bag-list-col-name-base {
    min-width: 8rem;
    max-width: 8rem;
}

.bag-list-col-composition-base {
    min-width: 25rem;
    max-width: 25rem;
}

.bag-list-col-pack-size-base {
    min-width: 8rem;
    max-width: 8rem;
}

.bag-list-col-qnt-base {
    min-width: 8rem;
    max-width: 8rem;
}

.bag-list-qnt-parent {
    width: 6rem;
    height: 2rem;
}

.bag-list-col-price-base {
    min-width: 3rem;
    max-width: 3rem;
}

.bag-list-col-select-distributor-base {
    min-width: 10rem;
    max-width: 10rem;
}

.bag-list-pack-size-selector {
    width: 7rem;
    height: 2rem;
}

.bag-list-selectors-light {
    border: 1px solid #b1b1b1;
    color: #303030;
    background-color: #fafafa;
}

.bag-list-selectors-dark {
    border: 1px solid #454545;
    color: #e8e8e3;
    background-color: #101010;
}

.bag-list-select-distributor-selector {
    width: 8rem;
    height: 2rem;
}

.items-list-cards {
    min-width: fit-content;
}

.items-list-cards-dark {
    background-color: #ffffff03;
    border-bottom: 1px solid rgba(255, 255, 255, 0.14);
    border-left: 1px solid rgba(255, 255, 255, 0.14);
    border-right: 1px solid rgba(255, 255, 255, 0.14);
}

.items-list-top-card-dark {
    border-top: 1px solid rgba(255, 255, 255, 0.14);
}


.items-list-cards-light {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    border-right: 1px solid rgba(0, 0, 0, 0.25);
}

.items-list-top-card-light {
    border-top: 1px solid rgba(0, 0, 0, 0.25);
}