.bag-overview-pane {
    height: calc(100vh - 58px - 2rem);
    margin: 1rem;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    border-radius: 5px;
    overflow-y: auto;
}

.bag-overview-pane-light {
    background-color: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.bag-overview-pane-dark {
    background-color: rgba(255, 255, 255, 0.03);
    border: 1px solid #353535;
}

.bag-header-parent {
    min-height: 190px;
}

.bag-content-parent {
    overflow-y: auto;
}

.bag-header-parent-light {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
    background-color: #fdfdfd;
}

.bag-header-parent-dark {
    background-color: #0a0a0a;
    border-bottom: 1px solid #353535;
}

.bag-content-parent-light {
    background-color: #fafafa;
}

.bag-content-parent-dark {
    background-color: #101010;
}