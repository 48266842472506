.side-panel-light {
    background-color: #fafafa;
    border-right: 1px solid #00000030;
}

.basics-box-toggle-light {
    background-color: #eeeeee;
}

.basics-box-light {
    background-color: #eeeeee;
}

.basics-box-light button {
    background-color: #d9d9d9;
    border: 0.5px solid transparent;
}

.basics-box-light button:hover {
    background-color: #b6b6b6;
}

.add-bags-btn-light {
    background-color: rgb(59 130 246);
}

.add-bags-btn-light:hover {
    background-color: rgb(37 99 235);
}

.active-bag-light {
    background: linear-gradient(90deg, #6A98F0 0%, #3273BF 100%);
    color: #fff;
}

.inactive-bag-light:hover {
    background-color: #e0e0e0;
}