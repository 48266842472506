.bag-header-title-light {
    color: #000;
}

.bag-header-title-dark {
    color: #fafafa;
}

.bag-header-description-light {
    color: #505050;
}

.bag-header-description-dark {
    color: #adadad;
}