@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    padding: 0;
    margin: 0;
}

.th {
    top: 50%;
}

.top-half-screen {
    top: 50vh;
}

.top-40-screen {
    top: 40vh;
}

.top-45-screen {
    top: 45vh;
}

.lh {
    left: 50%;
}

.ttf {
    transform: translate(-50%);
}

.ttzf {
    transform: translate(0, -50%);
}

.ttff {
    transform: translate(-50%, -50%);
}

.t-40percent {
    top: 40%;
}

.t-100px {
    top: 100px;
}

.w-1kpx {
    width: 1000px;
}

.h-180px {
    height: 180px;
}

.h-270px {
    height: 270px;
}

.w-240px {
    width: 240px;
}

.w-360px {
    width: 360px;
}

.mx--10px {
    margin-left: -10px;
    margin-right: -10px;
}

.h-60px {
    height: 60px;
}

.h-600px {
    height: 600px;
}

.h-700px {
    height: 700px;
}

.w-70per {
    width: 70%;
}

.w-60per {
    width: 60%;
}

.w-90per {
    width: 90%;
}

.transition-0p1 {
    transition: 0.1s;
}

/* Custom Border Styles */

.border-top-0p5-transparent {
    border-top: 0.5px solid transparent;
}

.border-bottom-0p5-transparent {
    border-bottom: 0.5px solid transparent;
}

.border-top-0p5-333 {
    border-top: 0.5px solid #33333320;
}

.border-bottom-0p5-333 {
    border-bottom: 0.5px solid #33333320;
}

.underline-on-hover:hover {
    text-decoration: underline;
}

.text-e9e9e3 {
    color: #e9e9e3;
}

.text-b9 {
    color: #b9b9b9;
}


/* Scroll Bars */

/* width::-webkit-scrollbar { */
/* width: 8px; */
/* } */

/* Track */
/* ::-webkit-scrollbar-track { */
/* background: #BEBEBE; */
/* width: 8px; */
/* } */

/* Handle */
/* ::-webkit-scrollbar-thumb { */
/* background: #ffffff; */
/* width: 5px; */
/* } */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover { */
/* background: #777; */
/* } */


/* ------------------------------------------------- */

/* New Scroll Bar */

/* Let's get this party started */
::-webkit-scrollbar {
    width: 10px;
    background: #bebebe;
}

::-webkit-scrollbar:hover {
    width: 15px;
}

::-webkit-scrollbar-thumb:window-inactive {
    width: 5px;
    border: 3px solid #777;
}


/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #fff;
    border: 3px solid #bebebe;
}

::-webkit-scrollbar:window-inactive {
    width: 8px;
    background: #777;
}