.side-panel {
    width: 20vw;
    min-width: 300px;
    max-width: 500px;
    /* height: 100%; */
    height: calc(100vh - 58px);
    overflow-y: scroll;
}

.image-box {
    width: 160px;
    height: 160px;
}

.basics-btns-box button {
    display: block;
    font-size: 1rem;
    min-height: 32px;
    margin-left: 16px;
    margin-right: 16px;
    text-align: start;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 3px;
    transition: 0.2s;
}

.add-bags-btn {
    transition: 0.2s;
    font-size: 0.8rem;
    color: #e8e8e3;
    border-radius: 3px;
}

.bags-btns-box button {
    display: block;
    font-size: 1rem;
    min-height: 48px;
    margin-left: 16px;
    margin-right: 16px;
    text-align: start;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 3px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    transition: 0.2s;
}