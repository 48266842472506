.side-panel-dark {
    background-color: rgba(255, 255, 255, 0.06);
    border-right: 1px solid #353535;
}

.basics-box-toggle-dark {
    background-color: rgba(0, 0, 0, 0.70);
    color: #fafafa;
}


.basics-box-dark {
    background-color: rgba(0, 0, 0, 0.70);
    color: #fafafa;
}

.basics-box-dark button {
    background-color: rgba(255, 255, 255, 0.02);
    border: 0.5px solid #454545;
}

.basics-box-dark button:hover {
    background-color: rgba(255, 255, 255, 0.09);
}

.bags-box-dark {
    color: #fafafa;
}

.add-bags-btn-dark {
    background-color: #1A66AC;
}

.add-bags-btn-dark:hover {
    background-color: #11426F;
}

/* .active-bag-dark {
    background-color: #2A3362;
} */

.active-bag-dark {
    background: linear-gradient(90deg, #6A98F0 0%, #3273BF 100%);
    color: #fff;
}

.inactive-bag-dark:hover {
    background-color: #2c2c2c;
}