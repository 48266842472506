.app {
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
}

.app-light {
    background-color: #e8e8e8;
}

.app-dark {
    background-color: #080808;
}

.bodypanel-parent {
    overflow-y: auto;
    width: 100%;
    height: calc(100vh - 58px);
}